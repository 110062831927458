import React, { Fragment } from "react"

const DisclaimerMessage = () => {
  return (
    <Fragment>
      Novartis Healthcare Philippines is committed to discovering new ways to
      transform healthcare by implementing programs designed to improve health
      and promote wellness.{" "}
      <div>
        <a class="mt-1 button is-small" href="/#program-enhancement">
          Learn More
        </a>
      </div>
    </Fragment>
  )
}

export default DisclaimerMessage
