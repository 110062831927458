import React from "react"
import DisclaimerMessage from "../../components/Layout/DisclaimerMessage"

const yearNow = new Date(Date.now()).getFullYear()

export const initialState = {
  filesArray: [],
  toast: {
    isActive: false,
    message: "",
    color: "",
  },
  cart: {
    customerFirstName: "",
    customerLastName: "",
    customerEmail: "",
    birthMonth: "",
    birthDate: "",
    birthYear: "",
    birthday: "",
    mobileNumber: "",
    contactPerson: ["Contact this number for order and delivery updates"],
    authorizedPerson: {
      fullName: "",
      mobileNumber: "",
    },
    couponCode: "",
    convenienceFee: 0,
    preferredDeliveryDate: {
      month: {
        value: null,
      },
      date: {
        value: null,
      },
      year: yearNow,
    },
    asepticDelivery: 100,
    shippingAddress: {
      addressType: "",
      streetAddress: "",
      barangay: { value: "" },
      city: { value: "" },
      province: { label: "Metro Manila", value: "Metro Manila" },
      postalCode: "",
      landmarks: "",
    },
    billingStreetAddress: "",
    billingBarangay: "",
    billingCityMunicipality: "",
    billingProvince: "Metro Manila",
    billingPostalCode: "",
    billingLandmarks: "",
    hospital: {
      name: { label: "", value: "" },
      otherHospital: "",
      province: { value: "" },
      city: { value: "" },
    },
    deliveryNotes: "",
    feedbackScore: "",
    feedbackField: "",
    prescription_notes: "",
    details: [],
    subtotal: "",
    discountAmount: "",
    prescribingDoctor: "",
    total: "",
    channel: "novartis",
    paymentMethod: "COD",
    bankName: "",
    changeFor: 0,
  },
  calculator: {
    product: {},
    totalQuantity: 0,
    purchaseQuantity: 0,
    medicineAssistance: 0,
  },
  disclaimer: {
    isActive: true,
    header: "To our Pulse Care Community",
    message: <DisclaimerMessage />,
    color: null,
  },
  modal: {
    isCard: false,
    isFullheight: false,
    isActive: false,
    content: null,
    heading: "",
    headerClass: null,
    hideCloseButton: false,
    background: {
      color: null,
      opacity: "100",
    },
  },
}
