import { initialState } from "./utils/initialState"

export const toast = {
  SHOW: "SHOW_TOAST",
  HIDE: "HIDE_TOAST",
}

export const cart = {
  SET_DETAILS: "SET_CART_DETAILS",
  CLEAR_DETAILS: "CLEAR_CART_DETAILS",
}

export const calculator = {
  SET_DETAILS: "SET_CALCULATOR_DETAILS",
  CLEAR_DETAILS: "CLEAR_CALCULATOR_DETAILS",
}

export const dropzone = {
  SET_DETAILS: "SET_DROPZONE_DETAILS",
  CLEAR_DETAILS: "CLEAR_DROPZONE_DETAILS",
}

const HIDE_DISCLAIMER = "HIDE_DISCLAIMER"
const modal = {
  SHOW_MODAL: "SHOW_MODAL",
  HIDE_MODAL: "HIDE_MODAL",
}

export const AppReducer = (state, action) => {
  switch (action.type) {
    case toast.SHOW:
      return { ...state, toast: { ...action.payload, isActive: true } }
    case toast.HIDE:
      return { ...state, toast: initialState.toast }
    case cart.SET_DETAILS:
      return { ...state, cart: action.payload }
    case cart.CLEAR_DETAILS:
      return { ...state, cart: initialState.cart }
    case calculator.SET_DETAILS:
      return { ...state, calculator: action.payload }
    case calculator.CLEAR_DETAILS:
      return { ...state, calculator: initialState.calculator }
    case dropzone.SET_DETAILS:
      return { ...state, filesArray: action.payload }
    case dropzone.CLEAR_DETAILS:
      return { ...state, filesArray: initialState.filesArray }
    case HIDE_DISCLAIMER:
      return { ...state, disclaimer: { ...action.payload, isActive: false } }
    case modal.SHOW_MODAL:
      return { ...state, modal: { ...action.payload, isActive: true } }
    case modal.HIDE_MODAL:
      return { ...state, modal: { ...initialState.modal, isActive: false } }
    case "CLEAR_ALL_DETAILS":
      return initialState
    default:
      return state
  }
}
