import React from "react"
import { AppReducer } from "./AppReducer"
import { initialState } from "./utils/initialState"
import Toast from "../components/page-content/toast"
import Disclaimer from "../components/Layout/Disclaimer"
import Modal from "../components/elements/Modal"

/**
 * creates the context
 */
const AppContext = React.createContext()

/**
 * AppProvider of the context.
 * use this as parent component if you want to use the AppContext values
 * @param {Component}
 */
const AppProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(AppReducer, initialState)

  const showToast = payload => {
    if (!state.toast.isActive) dispatch({ type: "SHOW_TOAST", payload })
  }

  return (
    <AppContext.Provider value={{ state, dispatch, showToast }}>
      <Modal
        isModalActive={state.modal.isActive}
        isFullheight={state.modal.isFullheight}
        closeModal={() => dispatch({ type: "HIDE_MODAL" })}
        heading={state.modal.heading}
        modalHeaderClass={state.modal.headerClass}
        modalBackground={state.modal.background}
        hideCloseButton={state.modal.hideCloseButton}
        isCard={state.modal.isCard}
      >
        {state.modal.content}
      </Modal>
      {children}
      <Toast {...state.toast} />
      {/* <Disclaimer
        isActive={state.disclaimer.isActive}
        message={state.disclaimer.message}
        header={state.disclaimer.header}
        color={state.disclaimer.color}
        closeDisclaimer={() => dispatch({ type: "HIDE_DISCLAIMER" })}
      /> */}
    </AppContext.Provider>
  )
}

export { AppContext, AppProvider }
